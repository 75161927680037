<template>
  <div class="footbar">
    <div class="footbar-content">
      <div class="content-menus">
        <div class="menu-item" v-for="(item,index) in $t('menuList')" :key="index" v-if="item.pathName===''">
          <div class="title">{{ item.name }}</div>
          <div class="padding-top12"></div>
          <div class="link" v-for="(value,key) in item.list" :key="key">
            <a :href="value.path">{{ value.name }}</a>
          </div>
        </div>
      </div>
      <div class="content-languages">
        <div class="language" v-if="languageData.length > 1">
          <label @click="showLanguage">{{ languageName }}</label>
          <ul v-if="isShowLanguage">
            <li v-for="item in languageData" :key="item.language_id" @click="changeLanguage(item.language_id, item.language_name)" :class="(item.language_name === languageName)?'select':''">{{ item.language_name }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="padding-top24"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'pcFootbar',
  computed: {
    ...mapGetters(['language', 'languageData', 'isMobile']),
  },
  data () {
    return {
      languageName: '',
      isShowLanguage: false
    }
  },
  created () {
    this.getLanguageName()
  },
  methods: {
    ...mapActions(['SetLanguage']),
    showLanguage() {
      if (!this.isShowLanguage) {
        this.isShowLanguage = true
      } else {
        this.isShowLanguage = false
      }
    },
    changeLanguage (languageId, name) {
      this.languageName = name
      this.isShowReason = false
      this.SetLanguage(languageId)
      window.location = window.location.href
    },
    getLanguageName () {
      if (this.languageName==='') {
        this.languageData.forEach(element => {
          if (this.language === element.language_id) {
            this.languageName = element.language_name
          }
        });
      }
    } 
  }
}
</script>

<style lang="scss">

</style>
