import Vue from 'vue'
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'
import { getConfigList } from '@/api/index';

const isMobile = utils.isMobile()
const isIPhone = utils.isIPhone()
// const systemInfo = utils.getSystemInfo()
const source = utils.getQueryParam('utm_source')
const position = utils.getQueryParam('ad_position')
const deviceId = storage.get('deviceId')

const state = {
  userInfo: {
    avatar: '',
    nickname: '',
    token: '',
    user_id: ''
  },
  userToken: '',
  staticUrl: window.staticUrl,
  newStaticUrl: window.newStaticUrl,
  // language: utils.getSystemLanguage(),
  language: window.language,
  isIPhone: isIPhone,
  isMobile: isMobile,
  deviceId: deviceId,
  // systemInfo: systemInfo,
  utmSource: source,
  adPosition: position,
  downloadUrl: {
    androidUrl: 'https://play.google.com/store/apps/details?id=com.XJWL.SSmetaverse',
    appstoreUrl: 'https://apps.apple.com/ph/app/id1636852237',
    pcUrl: 'http://g1.xyxverse.com/website/download/1.0.0/MetaversePlay.rar',
    qrCodeUrl: '',
    playUrl: '',
    videoUrl: 'https://g1.xyxverse.com/website/mass-173703.mp4',
    buyUrl: 'https://mass-3d-create-play-1.xsollasitebuilder.com/'
  },
  languageData:[
    {language_id: "en", language_name: "English"},
    {language_id: "cn", language_name: "中文"}
  ],
  configList: {}
}

const getters = {
  staticUrl: state => {
    let staticUrl = storage.get('staticUrl')
    if (staticUrl !== null) {
      state.staticUrl = staticUrl
    }
    return state.staticUrl
  },
  newStaticUrl: state => state.newStaticUrl,
  language: state => {
    let useLanguage = storage.get('useLanguage')
    if (useLanguage !== null) {
      state.language = useLanguage
    } else {
      state.language = window.language // utils.getSystemLanguage()
    }
    return state.language
  },
  isMobile: state => state.isMobile,
  isIPhone: state => state.isIPhone,
  deviceId: state => {
    if (state.deviceId === null || state.deviceId === '') {
      let deviceId = utils.getDeviceId()
      storage.set('deviceId', deviceId)
      state.deviceId = deviceId
    }
    return state.deviceId
  },
  // systemInfo: state => state.systemInfo,
  languageData: state => state.languageData,
  downloadUrl: (state) => {
    if (source !== '') {
      state.downloadUrl.androidUrl = state.downloadUrl.androidUrl.replace('cn_zhuzi', source)
      state.downloadUrl.appstoreUrl = 'https://apps.apple.com/cn/app/id6446186200?pt=126143720&ct=' + source + '&mt=8'
    }
    if (position !== '') {
      state.downloadUrl.androidUrl = state.downloadUrl.androidUrl + '/' + position
    }
    return state.downloadUrl
  },
  userInfo: (state) => {
    if (state.userInfo.token === '') {
      let userInfo = storage.get('userInfo')
      if (userInfo !== null) {
        state.userInfo = JSON.parse(userInfo)
      }
    }
    return state.userInfo
  },
  userToken: (state) => {
    if (state.userToken === '') {
      let userToken = storage.get('userToken')
      if (userToken !== null) {
        state.userToken = userToken
      }
    }
    return state.userToken;
  },
  configList: (state) => {
    if (state.configList === '') {
      let configList = storage.get('configList')
      if (configList !== null) {
        state.configList = JSON.parse(configList)
      }
    }
    return state.configList
  },
}

const mutations = {
  updateUserInfo (state, params) {
    storage.set('userInfo', JSON.stringify(params))
  },
  updateUserToken (state, userToken) {
    storage.set('userToken', userToken);
  },
  updateLanguage (state, language) {
    storage.set('useLanguage', language)
  },
  updateStaticUrl (state, staticUrl) {
    storage.set('staticUrl', staticUrl)
  },
  updateConfigList (state, configList) {
    storage.set('configList', JSON.stringify(configList))
  }
}

const actions = {
  GetParamsSign ({ commit }, params) {
    return new Promise((resolve, reject) => {
      let payKey = 'SDmd&2();]1ldD%^&'
      let strJoin = ''
      let newParams = {}
      if (JSON.stringify(params) == '{}' || params == null) {
        reject({ code: 1, msg: "参数不正确！" })
      } else {
        let key = Object.keys(params)?.sort()
        for (let i = 0; i < key.length; i++) {
          newParams[key[i]] = params[key[i]]
        }
        for (let key in newParams) {
          strJoin = strJoin + key + "=" + newParams[key] + "&"
        }
        let sign = md5(payKey + strJoin)
        resolve({ code: 0, msg: "OK！", data:{ str: strJoin, sign: sign } })
      }
    })
  },
  getConfigList ({ commit, state }) {
    // 发送手机验证码
    return new Promise((resolve, reject) => {
      getConfigList()
        .then((res) => {
          if (typeof(res.config_list) && res.config_list) {
            state.configList = res.config_list
            state.staticUrl = res.config_list.cdn_url
            state.languageData = res.config_list.language
            commit("updateStaticUrl", res.config_list.cdn_url)
            commit("updateConfigList", res.config_list)
          }
          // console.log(res)
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  SetUserInfo ({ commit,state }, data) {
    console.log(state.userInfo)
    state.userInfo = data
    commit("updateUserInfo", data)
    commit('updateUserToken', data.token);
  },
  SetUserToken ({ commit }, token) {
    commit('updateUserToken', token);
  },
  SetLanguage ({ commit,state }, language) {
    state.language = language
    commit("updateLanguage", language)
  },
  TestLogin ({ commit }) {
    let   userInfo = {
      user_id: "YYZ000007446849",
      nickname: "Perter worldUMpaY",
      avatar: "https://g1.xyxverse.com/avatar/initial_avatar_portrait_3.png",
      token: "7d63l2NJphfpiP1Vxf_8qafV0IEv7UwOFJ9ZbH1TEZFPKqLu8Ob4RcP_rQH7kFBdu0xjLIyruI58R95xOavzXwrKBp9-YZADe7AYGMxRvXPs_iVSxx5_48OG_tU-rVdX7MVMCNtqZj47J5kGvEyjXb5nSmD2gcprCxn8jwTWgR2plmrcA20IS_FA5f1fyUhn5brUTWpIkdcZ9C9wyX9XShtYHew3XhISmhrjWwbzscvEoEMmhoM"
    }
    commit('updateUserToken', userInfo.token);
    commit('updateUserInfo', userInfo)
  },
  UserOut ({ commit }) {
    let userInfo = {
      user_id: '',
      nickname: '',
      avatar: '',
      token: '',
    }
    commit('updateUserToken', userInfo.token);
    commit('updateUserInfo', userInfo)
  },
  ComingSoon () {
    Vue.prototype.showError('Coming Soon ！')
    return
  },
  downloadApp ({ commit,state }, type) {
    let url = ''
    if (type==='mobile') {
      if (typeof(state.configList.app_download_url) && state.configList.app_download_url) {
        url = state.configList.app_download_url
      }
    } else {
      if (typeof(state.configList.pc_editor_url) && state.configList.pc_editor_url) {
        url = state.configList.pc_editor_url
      }
    }
    if (url !=='') {
      let a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.click();
    }else {
      Vue.prototype.showError(type+' url is error ！')
    }
    return
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
