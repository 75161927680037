<template>
  <div class="dialog_login" @click="closeDialog">
    <div class="dialog_wrap_bg" @click.stop="noTouch">
      <div class="dialog_wrap_close" @click="closeDialog"></div>
      <div class="dialog_wrap_content">
        <img class="app-login-img" :src="codeInfo.qcode_url" />
        <div class="content-desc">
          <a @click="getQrcode">{{ $t('loginData.qrInfo.title')}}</a>
        </div>
        <div class="content-desc" v-html="$t('loginData.qrInfo.desc')"></div>
      </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getLoginQrcodeCreate, getLoginQrcodeCheck, getUserLogin } from '@/api/index'

export default {
  name: 'WrapCodeLogin',
  computed: {
    ...mapGetters([
      'userInfo',
      'isMobile',
      'newStaticUrl',
    ])
  },
  data () {
    return {
      time: 10,
      isCheck: false,
      timer: '',
      codeInfo: {
        qcode: '',
        qcode_url: ''
      },
      loginInfo:{
        login_type: 1,
        code: '',
        token: ''
      },
      userData: {}
    }
  },
  watch: {
    time: function (newVal) {
      if (!this.isCheck) {
        this.checkQrcode()
      }
      if (newVal === 0) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  mounted () {
    // 设定⼀个定时器，每⼀秒调⽤⼀次countTime⽅法，time-1
    this.timer = setInterval(this.countTime, 6000)
  },
  created () {
    this.getQrcode()
  },
  methods: {
    ...mapActions(['SetUserInfo']),
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    getQrcode () {
      getLoginQrcodeCreate().then(res => {
        this.codeInfo = res
        this.isCheck = false
      }).catch(error => {
        this.isCheck = true
        console.log(error)
      });
    },
    checkQrcode () {
      getLoginQrcodeCheck({ qcode: this.codeInfo.qcode }).then(res => {
        if (res.code === 999982) {
          this.isCheck = 1;
          this.getQrcode()
        }
        if(res.token){
          this.loginInfo.token = res.token
          this.loginInfo.code = res.code
          this.isCheck = 1;
          this.goLogin()
        }
      });
    },
    goLogin () {
      getUserLogin(this.loginInfo).then(res => {
        if (typeof(res.user_base) !== 'undefined') {
          this.userData = res.user_base
        }
        this.userData.token = res.token
        this.SetUserInfo(this.userData)
        this.$router.push({ name: 'home' })
      });
    },
    countTime () {
      this.time--
      console.log(this.time)
    }
  }
}
</script>

<style lang="scss">

</style>
