import { axios } from '@/utils/request'
import qs from 'qs'

// 联系我们
export function addWebsiteContactus (parameter) {
  return axios({
    url: '/website/contact/contactus',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取官方场景
export function getOfficalScenes (parameter) {
  return axios({
    url: '/website/scene/officalScenes',
    method: 'get',
    params: parameter
  })
}

// 获取用户场景
export function getUserScenes (parameter) {
  return axios({
    url: '/website/scene/userScenes',
    method: 'get',
    params: parameter
  })
}

// 获取用户场景详情
export function getScenesDetail (parameter) {
  return axios({
    url: '/website/scene/sceneDetail',
    method: 'get',
    params: parameter
  })
}

// 获取推荐场景
export function getScenesRecommend (parameter) {
  return axios({
    url: '/website/scene/recommend',
    method: 'get',
    params: parameter
  })
}

// 获取热门衣服
export function getGoodsHotClothes (parameter) {
  return axios({
    url: '/website/goods/hotClothes',
    method: 'get',
    params: parameter
  })
}

// 获取衣服详情
export function getGoodsDetail (parameter) {
  return axios({
    url: '/website/goods/goodsDetail',
    method: 'get',
    params: parameter
  })
}

// 获取推荐商品
export function getGoodsRecommend (parameter) {
  return axios({
    url: '/website/goods/recommend',
    method: 'get',
    params: parameter
  })
}

// 获取商品页banner列表
export function getGoodsBanner (parameter) {
  return axios({
    url: '/website/goods/banner',
    method: 'get',
    params: parameter
  })
}

// 获取达人列表
export function getUserExpertUser (parameter) {
  return axios({
    url: '/website/user/expertUser',
    method: 'get',
    params: parameter
  })
}

// 获取达人详情
export function getUserDetail (parameter) {
  return axios({
    url: '/website/user/userDetail',
    method: 'get',
    params: parameter
  })
}

// 获取推荐达人
export function getUserRecommend (parameter) {
  return axios({
    url: '/website/user/recommend',
    method: 'get',
    params: parameter
  })
}

// 获取taient story列表
export function getUserTaientStory (parameter) {
  return axios({
    url: '/website/user/taientStory',
    method: 'get',
    params: parameter
  })
}

// 获取taient story详情
export function getUserTaientStoryDetail (parameter) {
  return axios({
    url: '/website/user/taientStoryDetail',
    method: 'get',
    params: parameter
  })
}

// 获取create-TALENT
export function getUserHotusers (parameter) {
  return axios({
    url: '/website/user/hotusers',
    method: 'get',
    params: parameter
  })
}

// 登陆二维码生成
export function getLoginQrcodeCreate (parameter) {
  return axios({
    url: '/website/user/loginQrcodeCreate',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 登陆二维码轮询检测
export function getLoginQrcodeCheck (parameter) {
  return axios({
    url: '/website/user/loginQrcodeCheck',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 用户登录
export function getUserLogin (parameter) {
  return axios({
    url: '/website/user/login',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取文章列表(树形展示)
export function getArticleTree (parameter) {
  return axios({
    url: '/website/article/treeArticles',
    method: 'get',
    params: parameter
  })
}

// 获取文章列表(分页)
export function getArticleList (parameter) {
  return axios({
    url: '/website/article/list',
    method: 'get',
    params: parameter
  })
}

// 获取文章详情
export function getArticleDetail (parameter) {
  return axios({
    url: '/website/article/detail',
    method: 'get',
    params: parameter
  })
}

// 关注指定用户
export function getUserfriendFollow (parameter) {
  return axios({
    url: '/website/user/friendFollow',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 发送email校验码
export function getEmailCode (parameter) {
  return axios({
    url: '/website/user/emailCodeSend',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取房间信息
export function getRoomDetail (parameter) {
  return axios({
    url: '/website/room/roomDetail',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取房主公开的房间列表
export function getRoomRecommendMatser (parameter) {
  return axios({
    url: '/website/room/roomRecommendMatser',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 房间收藏
export function getRoomCollect (parameter) {
  return axios({
    url: '/website/room/roomCollect',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 场景点赞
export function getSceneStar (parameter) {
  return axios({
    url: '/website/scene/sceneStar',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取创作者排行
export function getUgcWorkerRank (parameter) {
  return axios({
    url: '/website/ugc/ugcWorkerRank',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取创造者商品列表 type_id	是商品类型  1 服饰  3 场景
export function getUgcBannerGoodsList (parameter) {
  return axios({
    url: '/website/ugc/ugcBannerGoodsList',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取充值列表
export function getPaymentList (parameter) {
  return axios({
    url: '/website/user/paymentList',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取充值记录
export function getPaymentLog (parameter) {
  return axios({
    url: '/website/user/paymentLog',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取网站配置
export function getConfigList (parameter) {
  return axios({
    url: '/website/config/list',
    method: 'get',
    params: parameter
  })
}
