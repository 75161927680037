import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/filter' // global filter
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Toast from '@/components/Toast/index.js'
import VueI18n from 'vue-i18n'

Vue.use(VueAwesomeSwiper)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.getters.language,//window.language, // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'cn': require('@/assets/languages/cn.json'), // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'en': require('@/assets/languages/en.json')
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.prototype.showSuccess = function (title) {
  Toast({message: title, type: 'success'})
}

Vue.prototype.showError = function (title) {
  Toast({message: title, type: 'warning'})
}
