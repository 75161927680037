import Vue from 'vue'
import VueRouter from 'vue-router'
import PrivacyView from '@/views/mobile/PrivacyView'
import LayoutBase from '@/views/LayoutBase'
import LayoutDownload from '@/views/LayoutDownload'


// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: LayoutBase,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue')
      },
      {
        path: '/creator',
        name: 'creator',
        component: () => import('@/views/CreatorView.vue')
      },
      {
        path: '/mass',
        name: 'mass',
        component: () => import('@/views/MassView.vue')
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('@/views/ContactUs.vue')
      },
      {
        path: '/roomList',
        name: 'roomList',
        component: () => import('@/views/RoomListView.vue')
      },
      {
        path: '/roomDetail',
        name: 'roomDetail',
        component: () => import('@/views/RoomDetailView.vue')
      },
      {
        path: '/tutorial',
        name: 'tutorial',
        component: () => import('@/views/TutorialView.vue')
      },
      {
        path: '/tutorialDetail',
        name: 'tutorialDetail',
        component: () => import('@/views/TutorialDetailView.vue')
      },
      {
        path: '/tutorials',
        name: 'tutorials',
        component: () => import('@/views/TutorialsView.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/NewsView.vue')
      },
      {
        path: '/newDetail',
        name: 'newDetail',
        component: () => import('@/views/NewDetailView.vue')
      },
      {
        path: '/privacyPolicy',
        name: 'privacy',
        component: () => import('@/views/PrivacyView.vue')
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/views/AgreementView.vue')
      },
      {
        path: '/userCreate',
        name: 'userCreate',
        component: () => import('@/views/UserCreateView.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/OrderView.vue')
      },
      {
        path: '/articleDetail',
        name: 'articleDetail',
        component: () => import('@/views/ArticleDetailView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/loginForm',
    name: 'loginForm',
    component: () => import('@/views/LoginFormView.vue')
  }
  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
