<template>
  <div :class="isMobile?'mobile_dialog_wrap':'dialog_wrap'">
    <div class="dialog_wrap_back" @click.stop="noTouch">
      <div class="dialog_wrap_content">
        <div class="content-title">{{ title }}</div>
        <div class="content-info" v-if="content">{{ content }}</div>
        <div class="dialog-btn" v-if="showCancel">
          <div class="btn-short cancel" @click="tapClose">{{ cancelName }}</div>
          <div class="btn-short ok" @click="tapSure">{{ sureName }}</div>
        </div>
        <div class="dialog-btn" v-else>
          <div class="btn-long ok" @click="tapSure">{{ sureName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomDialog',
  computed: {
    ...mapGetters([ 'isMobile' ])
  },
  props: {
    title: {
      type: String,
      default: "提示消息",
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    cancelName: {
      type: String,
      default: "取消",
    },
    sureName: {
      type: String,
      default: "确定",
    },
    content: {
      type: String,
      default: "",
    }
  },
  methods: {
    noTouch () {},
    tapClose () {
      this.$emit('onClose', true)
    },
    tapSure () {
      this.$emit('onSure', 11111)
    },
  },
  created() {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  }
}
</script>
