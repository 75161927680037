<template>
  <div :class="isMobile?'mobile-menubar':'menubar'">
    <div class="menubar-items">
      <div class="items" v-for="(items,index) in $t('menuList')" :key="index" v-if="items.list.length > 0">
        <div class="padding-top24"></div>
        <div class="item">
          <div class="name">{{ items.name }}</div>
          <div class="icon" @click="showClick(items.name, isShow)">
            <img src="@/assets/images/icon_up.png" v-if="currentActive === items.name && isShow" />
            <img src="@/assets/images/icon_down.png" v-else/>
          </div>
        </div>
        <ul class="item-sub" :class="{ active: currentActive === items.name && isShow }">
          <li class="sub" v-for="(item,key) in items.list" :key="key" @click="goItemPath(item.path)" >{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div class="padding-top50"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NewMenuBar',
  computed: {
    ...mapGetters(['isMobile']),
  },
  data () {
    return {
      currentActive: '',
      isShow: 0,
    }
  },
  created () {},
  methods: {
    showClick(name, isShow) {
      this.currentActive = name
      if (isShow) {
        this.isShow = 0
      } else {
        this.isShow = 1
      }
      console.log(name)
    },
    goItemPath (url) {
      window.location.href = url
    },
  }
}
</script>
