import Vue from 'vue'
import axios from 'axios'
import { VueAxios } from './axios'
import store from '@/store'

var language = store.getters.language
var userToken = store.getters.userToken

// 创建 axios 实例
const service = axios.create({
  // baseURL: location.protocol + process.env.VUE_APP_API_BASE_URL, // api base_url
  baseURL: window.apiUrl,
  timeout: 6000 // 请求超时时间
})

const err = (error) => {
  var errorMessage
  if (error.response) {
    errorMessage = error.response.data
  } else {
    errorMessage = error.toString()
  }
  Vue.prototype.showError(errorMessage)
  // Vue.prototype.showNotify(errorMessage, 'error', () => {});
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  if (typeof(config.params) && config.params) {
    config.params.lang = language
    if (typeof(config.params.isgo) && config.params.isgo) {
      if (userToken) {
        config.headers['Bearer'] = userToken
      }
      // Vue.delete(config.params, 'isgo');
    }
  }

  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  const res = response.data
  if (res.code === 0) {
    return res.data
  } else if (res.code === 999982 || res.code === 999981) {
    return { code: res.code }
  } else {
    Vue.prototype.showError(res)
    return Promise.reject(res)
  }
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
