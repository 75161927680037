<template>
  <router-view/>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'LayoutBase',
    data () {
      return {}
    },
    computed: {
      ...mapGetters(['isMobile', 'language']),
      key(){
        return this.$route.name?this.$route.name+ +new Date():this.$route+ +new Date()
      }
    },
    created () {},
    methods: {}
  }
</script>

<style lang="scss">

</style>
