<template>
    <div id="app">
      <!-- <new-top-bar/> -->
      <top-bar :showLogin="false" :showDownload="false" v-if="isMobile" />
      <top-bar :showGems="true" :showDownload="true" v-if="!isMobile" />

      <div :class="isMobile?'mobile-main':'main'">
        <router-view/>
        <div class="bg-black">
          <download-bar v-if="!isMobile" />
        </div>
        <div class="bg-white">
          <pc-foot-bar v-if="!isMobile" />
        </div>
      </div>
    </div>
  </template>

<script>
  import { mapGetters } from 'vuex'
  import { TopBar, DownloadBar, PcFootBar } from '@/components'

  export default {
    name: 'LayoutDownload',
    data () {
      return {}
    },
    components: {
      TopBar,
      DownloadBar,
      PcFootBar
    },
    computed: {
      ...mapGetters(['isMobile','newStaticUrl']),
    },
    created () {},
  }
</script>

<style lang="scss">
  @import "@/assets/styles/index.scss";
</style>
