<template>
  <router-view :key="key"></router-view>
</template>

<script>

import { mapGetters,mapActions } from 'vuex'

export default {
  name: 'App',
  data () {
    return {}
  },
  computed:{
    ...mapGetters(['staticUrl', 'language', 'userInfo']),
    key(){
      return this.$route.name?this.$route.name+ +new Date():this.$route+ +new Date()
    },
  },
  created () {
    document.title = window.documentTitle
    this.SetLanguage(this.language)
    this.getConfigList()
  },
  methods: {
    ...mapActions(['SetLanguage', 'getConfigList'])
  }
}
</script>
