<template>
  <div :class="isMobile?'mobile-download':'download'">
    <div class="center">
      <div class="center-title" v-if="isMobile">{{ $t('downloadData.mobile.title') }}</div>
      <div class="center-title" v-else>{{ $t('downloadData.pc.title') }}</div>
      <div class="center-desc">
        <div v-if="isMobile">{{ $t('downloadData.mobile.desc') }}</div>
        <div class="padding-top24" v-else></div>
      </div>
      <div class="center-items"  v-if="isMobile">
        <div class="item" v-for="(item,index) in $t('downloadData.mobile.list')" :key="index" @click="downloadApp(item.name, item.downloadUrl)">
          <img :src="newStaticUrl+item.imgUrl" class="item-img" />
        </div>
      </div>
      <div class="center-items"  v-else>
        <div class="item" v-for="(item,index) in $t('downloadData.pc.list')" :key="index" @click="downloadApp(item.name, item.downloadUrl)">
          <img :src="newStaticUrl+item.imgUrl" class="item-img"  />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'downloadBar',
  computed: {
    ...mapGetters(['newStaticUrl', 'isMobile','userInfo'])
  },
  created () {},
  methods: {
    downloadApp (id, url) {
      if (url) {
        if (this.userInfo.user_id !== '') {
          url = url + '?user-id=' + this.userInfo.user_id
        }
        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        a.setAttribute('id', id);
        if (!document.getElementById(id)) {
          document.body.appendChild(a);
        }
        a.click();
      } else {
        this.showError('Coming Soon ！')
        return false;
      }
    }
  }
}
</script>